import { Component, OnInit, Input } from '@angular/core';
import { NavParams, LoadingController } from '@ionic/angular';
import { Components } from '@ionic/core';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import * as moment from 'moment';
import { ErrorService } from 'src/app/services/error/error.service';

declare var Croppie: any;

@Component({
  selector: 'app-pet-photo-add',
  templateUrl: './pet-photo-add.page.html',
  styleUrls: ['./pet-photo-add.page.scss'],
})
export class PetPhotoAddPage implements OnInit {
  url:any = null;
  image:any = null;
  photo:any = null;
  crop:any = null;
  cropping:any = true;
  preview:any = false;
  @Input() modal: Components.IonModal;
  constructor(
    private navParams: NavParams,
    private errorService: ErrorService,
    private loadingCtrl: LoadingController
  ) {
    this.image = this.navParams.get('image');
  }
  
  ngOnInit() {
    this.photoCropInit();
  }
  back(){
    this.modal.dismiss({
      url: this.url
    })
  }
  photoCropInit(){
    this.cropping = true;
    setTimeout(()=>{
      let extention = this.photoGetExtention();
      let orientation = 1;
      // if(extention == 'jpeg'){
      //   orientation = 6;
      // }
      this.crop = new Croppie(
        document.getElementById("photo-crop"),
        {
          viewport: {
            width: 200,
            height: 200,
            type: 'square'
          },
          boundary: {
            width: 300,
            height: 300
          },
          enableExif: true,
          enableOrientation: true
        }
      );
      this.crop.bind({
        url: this.image,
        orientation: orientation
      });
    }, 500);
  }
  photoRotate(degress:any){
    this.crop.rotate(degress);
  }
  photoGenerateHash() {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < 8; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  photoGetOrientation(){
    return new Promise (function (resolve) {
      var i = new Image()
      i.onload = ()=>{
        let extention = this.photoGetExtention();
        let value = 1;
        if(extention == 'jpeg'){
          value = 5;
        }
        resolve({ value: value })
      };
      i.src = this.image;
    })
  }
  photoGetExtention(){
    return this.image.split(',')[0].split('/')[1].split(';')[0];
  }
  photoUploadToStorage(){
    return new Promise((resolve, reject) => {
      let storageRef = firebase.storage().ref(),  
          date = moment(),
          imageName = this.photoGenerateHash()+"-"+date.format('YYYYMMDDHHMMSS'),
          imageRef = storageRef.child('images/pets').child(imageName);
      imageRef.putString(this.photo, 'data_url')
      .then((result) => {
        resolve(result.ref.getDownloadURL())
      }, error => {
        reject(error);
      });
    });
  }
  photoAddContinue(){
    this.cropping = false;
    this.crop.result({
      format: 'base64',
      size: {
        width: 600,
        height: 600
      }
    }).then((img:any)=>{
      this.photo = img;
      this.preview = true;
    });
  }
  photoAddCropCancel(){
    this.preview = false;
    this.photo = null;
    this.photoCropInit();
  }
  async photoAddSave(){
    let loading = await this.loadingCtrl.create({
      message: 'Salvando a foto...'
    });
    loading.present();
    this.photoUploadToStorage().then((url:any)=>{
      loading.dismiss();
      this.url = url;
      this.back();
    }).catch((error:any)=>{
      loading.dismiss();
      this.errorService.firebaseError(error);
    });
  }
}

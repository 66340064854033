import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class BreedsService {

  private collection:string = 'breeds';
  constructor(
    private afs: AngularFirestore    
  ) { }
  syncDocRef(docRef:any){
    return this.afs.collection(this.collection).doc(docRef).update({ docRef: docRef });
  }
  getByDoc(doc:any){
    return this.afs.collection(this.collection).doc(doc).valueChanges();
  }
  getAll(){
    return this.afs.collection(this.collection).valueChanges();
  }
  save(model:any){
    return new Promise((resolve, reject)=>{
      this.afs.collection(this.collection).add(model)
      .then((result:any)=>{
        this.syncDocRef(result.id).then(()=>{
          this.getByDoc(result.id).subscribe((pet:any)=>{
            resolve(pet);
          });
        }).catch((error:any)=>{
          reject(error);
        });
      }).catch((error:any)=>{
        reject(error);
      });
    });
  }
}

export class VaccinationCardDoseDates{
    last:string = null;
    next:string =null;
    constructor(){}
    importFromModel(model:VaccinationCardDoseDates){
        this.last = model.last;
        this.next = model.next;
    }
    format(){
        return {
            last: this.last,
            next: this.next
        }
    }
}
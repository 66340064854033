import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-com-pet-view',
  templateUrl: './com-pet-view.page.html',
  styleUrls: ['./com-pet-view.page.scss'],
})
export class ComPetViewPage implements OnInit {
  slidesOptions:any = {
    
  };
  place:any = null;
  @Input() modal: Components.IonModal;
  constructor(
    public navCtrl: NavController,
    private storage: Storage
  ) { }

  ngOnInit() {
    this.storage.get('com-pet:view').then((place:any)=>{
      this.place = place;
    });
  }
  back(){
    this.modal.dismiss();
  }
}

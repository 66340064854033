import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from 'angularfire2/auth';
import { Events } from '@ionic/angular';
import { Facebook } from '@ionic-native/facebook/ngx';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    user:any = null;
    constructor(
        private angularFireAuth: AngularFireAuth,
        private events: Events,
        private facebook: Facebook
    ) { 
        this.angularFireAuth.authState.subscribe((user:any)=>{
            this.events.publish('auth:statusChanged', user);
        });
    }
    getUserLogged(){
        return this.user;
    }
    private prepareUserAuth(user:any){
        let _user = JSON.parse(user),
            User = {
                email: _user.email,
                password: _user.password
            };
        return User;
    }
    createAccount(user:any){
        let User:any = user;
        return this.angularFireAuth.auth.createUserWithEmailAndPassword(User.email, User.password);
    }
    signIn(user:any){
        let User:any = this.prepareUserAuth(user);
        return this.angularFireAuth.auth.signInWithEmailAndPassword(User.email, User.password);
    }
    updateDisplayName(displayName:string){
        let User:any = {
            displayName: displayName
        };
        return this.angularFireAuth.auth.currentUser.updateProfile(User);
    }
    updatePhotoUrl(User:any){
        return this.angularFireAuth.auth.currentUser.updateProfile(User);
    }
    signWithFacebookNative(){
        return new Promise((resolve, reject) => {
            this.facebook.login(['email']).then((fbResponse:any)=>{
                resolve(fbResponse);
            }).catch((error:any)=>{
                reject(error);
            });
        });
    }
    signWithFacebookWeb(){
        return new Promise((resolve, reject) => {
            let provider = new firebase.auth.FacebookAuthProvider();
            this.angularFireAuth.auth.signInWithPopup(provider)
            .then((user:any)=>{
                resolve(user);
            }).catch((error:any)=>{
                reject(error);
            });
        });
    }
    signWithFacebookCredential(fbToken:any){
        const facebookCredential = firebase.auth.FacebookAuthProvider.credential(fbToken);
        return firebase.auth().signInWithCredential(facebookCredential);
    }
    signWithGoogle(){
        return new Promise((resolve, reject)=>{
            let provider = new firebase.auth.GoogleAuthProvider();
            this.angularFireAuth.auth.signInWithPopup(provider)
            .then((user:any)=>{
                resolve(user);
            }).catch((error:any)=>{
                reject(error);
            });
        });
    }
    // signWithGoogleNative(){
    //     return new Promise((resolve, reject)=>{
    //         this.googlePlus.login({}).then((res)=>{
    //             resolve(res);
    //         }).catch((err) => {
    //             reject(err);
    //         });
    //     });
    // }
    signWithGoogleCredential(googleToken:any){
        const facebookCredential = firebase.auth.GoogleAuthProvider.credential(googleToken);
        return firebase.auth().signInWithCredential(facebookCredential);
    }
    fetchSignInMethodsForEmail(user:any){
        return firebase.auth().fetchSignInMethodsForEmail(user.email);
    }
    signInWithEmailAndPasswordSyncFacebook(user:any){
        return firebase.auth().signInWithEmailAndPassword(user.email, user.password);
    }
    signOut(){
        return this.angularFireAuth.auth.signOut();
    }
    resetPassword(email: string){
        return this.angularFireAuth.auth.sendPasswordResetEmail(email);
    }
    deleteAccount(){
        return this.angularFireAuth.auth.currentUser.delete();
    }
}

import { VaccineDose } from './vaccine-dose';

export class Vaccine{
    name:string = null;
    description:string = null;
    petAge:string = null;
    petType:string = null;
    doses:Array<VaccineDose> = [];
    format(){
        let doses = [];
        if(this.doses.length > 0){
            this.doses.forEach((item:VaccineDose)=>{
                let dose = new VaccineDose();
                dose.importFromModel(item);
                doses.push(item.format());
            })
        }
        return{
            name: this.name,
            description: this.description,
            petAge:this.petAge,
            petType:this.petType,
            doses: doses
        }
    }
    importFromModel(model:Vaccine){
        this.name = model.name;
        this.description = model.description;
        this.petAge = model.petAge;
        this.petType = model.petType;
        if(model.doses.length>0){
            model.doses.forEach((item:VaccineDose)=>{
                var vaccineDose = new VaccineDose();
                vaccineDose.importFromModel(item);
                this.doses.push(vaccineDose);
            });
        }
    }
}
import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-condominiums-pet-view',
  templateUrl: './condominiums-pet-view.page.html',
  styleUrls: ['./condominiums-pet-view.page.scss'],
})
export class CondominiumsPetViewPage implements OnInit {
  slidesOptions:any = {
    
  };
  pet:any = null;
  @Input() modal: Components.IonModal;
  constructor(
    public navCtrl: NavController,
    private storage: Storage
  ) { }

  ngOnInit() {
    this.storage.get('condominium-pet:view').then((pet:any)=>{
      this.pet = pet;
    });
  }
  back(){
    this.modal.dismiss();
  }
}

import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private alertCtrl: AlertController
  ) { }
  public async appError(error:string, callback:any = null){
    let msg:string = "";
    switch(error){
      case 'email-empty':
        msg = 'E-mail está vazio';
        break;
      case 'email-invalid':
        msg = 'E-mail está inválido';
        break;
      case 'password-empty':
        msg = 'Senha está vazio';
        break;
      case 'passwordConfirm-empty':
        msg = 'Senha de Confirmação está vazia';
        break;
      case 'passwords-incompatible':
        msg = 'Senhas não são compatíveis';
        break;
      case 'birthdate-invalid':
        msg = 'Data de nascimento inválida';
        break;
      case 'document-invalid':
        msg = 'Documento inválido';
        break;
      case 'phone-invalid':
        msg = 'Telefone inválido';
        break;
      case 'phone-empty':
        msg = 'Telefone está vazio';
        break;
    }
    const alert = await this.alertCtrl.create({
      header: 'Ops :(',
      subHeader: 'Algo não esperado aconteceu.',
      message: msg,
      buttons: [
        {
          text: 'Ok',
          handler: ()=>{
            if(callback!=null){
              callback();
            }
          }
        }
      ]
    });
    await alert.present();
  }
  public async singleAlert(options:any, callback:any = null){
    let msg:string = "";
    const alert = await this.alertCtrl.create({
      header: options.header,
      subHeader: options.subHeader,
      message: options.message,
      buttons: [
        {
          text: 'Ok',
          handler: ()=>{
            if(callback!=null){
              callback();
            }
          }
        }
      ]
    });
    await alert.present();
  }
  public async print(error:string){
    const alert = await this.alertCtrl.create({
      header: 'Ops :(',
      subHeader: 'Algo não esperado aconteceu.',
      message: error,
      buttons: ['Ok']
    });
    await alert.present();
  }
  public async firebaseError(error:string){
    let msg:string = "";
    switch(error){
      case "auth/invalid-email":
        msg = "E-mail inválido";
        break;
      case "auth/user-disabled":
        msg = 'Usuário desativado';
        break;
      case "auth/user-not-found":
        msg = 'Usuário não encontrado, cadastre-se para acessar o aplicativo';
        break;
      case "auth/wrong-password":
        msg = 'Senha incorreta, tente novamente';
        break;
      case "auth/email-already-in-use":
        msg = 'Este e-mail já está em uso, tente fazer login ou recuperar sua senha.';
        break;
      case "auth/invalid-email":
        msg = 'Este e-mail é inválido, tente outro';
        break;
      case "auth/operation-not-allowed":
        msg = 'Esta operação não está autorizada.';
        break;
      case "auth/weak-password":
        msg = 'Sua senha é muito fraca, coloque outra e tente novamente.';        
        break;
      case "auth/popup-blocked":
        msg = 'As janelas de popup estão bloqueadas.';        
        break;
      case "auth/popup-closed-by-user":
        msg = 'A janela foi fechada por você.';        
        break;
      case "auth/account-exists-with-different-credential":
        msg = 'Você já tem conta no Petspot mas utilizou outra forma de login.';        
        break;
      default:
        msg = error;
        break;
    }
    const alert = await this.alertCtrl.create({
      header: 'Ops :(',
      subHeader: 'Algo não esperado aconteceu.',
      message: msg,
      buttons: ['Ok']
    });
    await alert.present();
  }
}

export class Address{
    type:string = "";
    text:string = "";
    lat:string = "";
    lng:string = "";
    street:string = "";
    streetNumber:string = "";
    district:string = "";
    complement:string = "";
    zipCode:string = "";
    city:string = "";
    state:string = "";
    country:string = "";
    user:string = "";
    getText() {
        if(
            this.street != '' &&
            this.district != '' &&
            this.city != '' &&
            this.state != ''
        ){
            if(this.complement !='' && this.complement != null){
                if(this.streetNumber!=''&& this.streetNumber != null){
                    this.text = this.street+" "+this.streetNumber+" - "+this.district+" - "+this.complement+" - "+this.city+" - "+this.state;                    
                }else{
                    this.text = this.street+" - "+this.district+" - "+this.complement+" - "+this.city+" - "+this.state;                    
                }
            }else{
                if(this.streetNumber!=''&& this.streetNumber != null){
                    this.text = this.street+" "+this.streetNumber+" - "+this.district+" - "+this.city+" - "+this.state;
                }else{
                    this.text = this.street+" - "+this.district+" - "+this.city+" - "+this.state;
                }
            }
        }
        return this.text;
    }
    getTextNotNumber() {
        if(
            this.street != '' &&
            this.district != '' &&
            this.city != '' &&
            this.state != ''
        ){
            if(this.complement !='' && this.complement != null){
                this.text = this.street+" - "+this.district+" - "+this.complement+" - "+this.city+" - "+this.state; 
            }else{
                this.text = this.street+" - "+this.district+" - "+this.city+" - "+this.state;
            }
        }
        return this.text;
    };
    format() {
        return {
            type: this.type,
            text:this.text,
            lat:this.lat,
            lng:this.lng,
            street:this.street,
            streetNumber:this.streetNumber,
            district:this.district,
            complement:this.complement,
            zipCode:this.zipCode,
            city:this.city,
            state:this.state,
            country:this.country,
            user:this.user
        }
    }
}
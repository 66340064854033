import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';
import { NavController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-pet-delete-confirm',
  templateUrl: './pet-delete-confirm.page.html',
  styleUrls: ['./pet-delete-confirm.page.scss'],
})
export class PetDeleteConfirmPage implements OnInit {
  @Input() modal: Components.IonModal;
  title:string = '';
  constructor(
    public navCtrl: NavController,
    private navParams: NavParams
  ) {
    let title = this.navParams.get('title');
    if(title){
      this.title = title;
    }else{
      this.title = 'Deletar cadastro?';
    }
   }

  ngOnInit() {
  }
  cancel(){
    this.modal.dismiss({
      status: false
    });
  }
  ok(){
    this.modal.dismiss({
      status: true
    });
  }
}

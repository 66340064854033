import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NavParams, NavController, AlertController, Platform, LoadingController } from '@ionic/angular';
import { Components } from '@ionic/core';
import { Address } from 'src/app/models/address';
import { HttpClient } from "@angular/common/http";
import { ErrorService } from 'src/app/services/error/error.service';
import { AddressService } from 'src/app/services/address/address.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import * as cityStates from '../../../assets/cidade-estado.json'

declare var google: any;
@Component({
  selector: 'app-address-add',
  templateUrl: './address-add.page.html',
  styleUrls: ['./address-add.page.scss'],
})
export class AddressAddPage implements OnInit {
  @ViewChild('map') mapElement: ElementRef;
  map: any;
  marker:any = null;
  user:any = null;
  type:any = null;
  address:any = new Address();
  streetAutoComplete:any = [];
  config:any = {
    manually: false,
    loading: true
  };
  @Input() modal: Components.IonModal;
  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private alertCtrl: AlertController,
    private platform: Platform,
    private http: HttpClient,
    private errorService: ErrorService,
    private addressService: AddressService,
    private loadingCtrl: LoadingController,
    private geolocation: Geolocation
  ) { 
    this.user = this.navParams.get('user');
    this.type = this.navParams.get('type');
    this.address.type = "MINHA CASA";
  }
  ngOnInit() {
    this.mapsLoad();
  }
  ionViewDidEnter(){
    if(
      this.platform.is('pwa') ||
      this.platform.is('mobileweb') ||
      this.platform.is('desktop') 
    ){
      this.addressLatLngGetFromWeb();
    }else{
      this.addressLatLngGetFromNative();
    }
  }
  back(){
    this.modal.dismiss();
  }
  //MAPS
  mapsLoad(){
    let latlng = new google.maps.LatLng(-23.533773, -46.625290),
        mapOptions = {
          center: latlng,
          zoom: 16,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          disableDefaultUI: true,
          styles: [
            {
              "featureType": "poi",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            }
          ]
        };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
  }
  mapsUpdateCenter(){
    let latlng = new google.maps.LatLng(this.address.lat, this.address.lng)
    this.map.setCenter(latlng);
  }
  mapsPinMakerSetInMap(){
    let latlng = new google.maps.LatLng(this.address.lat, this.address.lng),
        iconBase = './assets/xd-icons/',
        iconSrc = iconBase;
    if(this.marker!=null){
      this.marker.setMap(null);
    }
    if(this.type){
      switch(this.type){
        case 'lost':
          iconSrc = iconBase+'pinpoint-type-lost.svg';
          break;
        case 'disappeared':
          iconSrc = iconBase+'pinpoint-type-disappeared.svg';
          break;
        case 'adoption':
          iconSrc = iconBase+'pinpoint-type-adoption.svg';
          break;
        default:
          iconSrc = iconBase+'pinpoint-type-disappeared.svg';
          break;
      }
    }else{
      iconSrc = iconBase+'pinpoint-type-lost.svg';
    }
    this.marker = new google.maps.Marker({
          position: latlng,
          icon: iconSrc,
          animation: google.maps.Animation.DROP,
          draggable: true
        });
    if(!this.config.manually){
      this.marker.addListener('dragend', (event:any)=>{
        this.mapsPinMakerRepositioned(event);
      });
    }
    this.marker.setMap(this.map);
  }
  mapsPinMakerRepositioned(event:any){
    this.address.lat = event.latLng.lat();
    this.address.lng = event.latLng.lng();
    this.address.streetNumber = '';
    this.config.loading = true;
    this.mapsLatLngGeocode().then((geocodeResult)=>{
      this.config.loading = false;
      this.mapsGeocodeExtract(geocodeResult); 
      this.mapsPinMakerSetInMap();
      this.mapsUpdateCenter();
    }).catch((error:any)=>{
      this.mapsLatLngGeocodeError(error);
    });
  }
  mapsGeocodeIsPolitical(types:any){
    let result = false;
    types.forEach((item:any)=>{
      if(item == 'political' || item === 'street_number'){
        result = true;
      }
    });
    return result;
  }
  mapsGeocodeGetPoliticalType(types:any){
    let result =  '';
    types.forEach((item:any)=>{
      if(item != 'political'){
        result = item;
      }
    });
    return result;
  }
  mapsGeocodeExtract(geocode:any){
    geocode.forEach((item:any)=>{
      if(this.mapsGeocodeIsPolitical(item.types)){
        switch(this.mapsGeocodeGetPoliticalType(item.types)){
          case 'sublocality_level_1':
            //DISTRICT
            this.address.district = item.long_name;
            break;
          case 'administrative_area_level_2':
            //CITY
            this.address.city = item.long_name;
            break;
          case 'administrative_area_level_1':
            //STATE
            this.address.state = item.short_name;
            break;
          case 'country':
            //COUNTRY
            this.address.country = item.short_name;
            break;
          case 'street_number':
            this.address.streetNumber = item.long_name
            break;
        }
      }else{
          switch(item.types[0]){
            case 'premise':
                this.address.street = item.long_name
                break;
            case 'route':
                this.address.street = item.long_name
                break;
            case 'postal_code':
                this.address.zipCode = item.long_name
                break;
            case 'street_address':
                this.address.street = item.long_name
                break;
            case 'street_number':
                // this.address.streetNumber = item.long_name
                break;
          }
      }
    });
  }
  mapsLatLngGeocode(){
    return new Promise((resolve, reject)=>{
      var geocoder = new google.maps.Geocoder,
          latLng = { lat: this.address.lat, lng: this.address.lng };
      this.address.streetNumber = '';
      geocoder.geocode({'location': latLng }, (geocodeResult:any, status:any)=>{
        if(status === 'OK'){       
          resolve(geocodeResult[0].address_components);
        }else{
          reject(status);
        }
      });
    });
  }
  mapsAddressGeocode(){
    return new Promise((resolve, reject)=>{
      var geocoder = new google.maps.Geocoder,
          address = this.address.getTextNotNumber();
      this.address.streetNumber = '';
      geocoder.geocode({'address': address }, (geocodeResult:any, status:any)=>{
        if(status === 'OK'){
          resolve({
            lat: geocodeResult[0].geometry.location.lat(),
            lng: geocodeResult[0].geometry.location.lng()
          });
        }else{
          reject(status);
        }
      });
    });
  }
  mapsAddressZipCodeApiGeocode(){
    return new Promise((resolve, reject)=>{
      let zipCode = String(this.address.zipCode).replace('-', ''),
          url:string  = "https://viacep.com.br/ws/"+zipCode.replace('-', '')+"/json/";
      this.http.get(url, {})
      .subscribe((data: any) => {
        if(!data.erro){
          resolve({
            street: data.logradouro,
            district: data.bairro,
            state: data.uf,
            city: data.localidade
          });
        }else{
          reject('CEP não encontrado');
        }
      }), (error:any)=>{
          reject('CEP não encontrado');
      };
    });
  }
  async mapsLatLngGeocodeError(status:any){
    let alert = await this.alertCtrl.create({
      header: 'Ops!',
      subHeader: 'Algo de não esperado aconteceu.',
      message: 'Não foi possível pegar a localização: '+ status,
      buttons: ['Ok']
    });
    return await alert.present();
  }
  //ADDRESS
  addressTypeGetOptions(){
    let result = [
      {
        name: 'type-casa',
        type: 'radio',
        label: 'MINHA CASA',
        value: 'MINHA CASA',
        checked: false,
        color: 'warning'
      },
      {
        name: 'type-parceira',
        type: 'radio',
        label: 'CASA NAMORADA(O)',
        value: 'CASA NAMORADA(O)A',
        checked: false,
        color: 'warning'
      },
      {
        name: 'type-avos',
        type: 'radio',
        label: 'CASA AVÓS',
        value: 'CASA AVÓS',
        checked: false,
        color: 'warning'
      },
      {
        name: 'type-outro',
        type: 'radio',
        label: 'OUTRO',
        value: 'OUTRO',
        checked: false,
        color: 'warning'
      }
    ];
    switch(this.address.type){
      case 'MINHA CASA':
        result[0].checked = true;
        break;
      case 'CASA NAMORADA(O)':
        result[1].checked = true;
        break;
      case 'CASA AVÓS':
        result[2].checked = true;
        break;
      case 'OUTRO':
        result[3].checked = true;
        break;
    }
    return result;
  }
  addresssTypeChanged(type:string){
    this.address.type = type;
  }
  // addressStateChanged(state:string){
  //   this.address.state = state
  // }
  // addressCityChanged(city: string){
  //   this.address.city = city
  // }
  async addressTypeSelect(){
    let inputs = [];
    inputs = this.addressTypeGetOptions();
    let alert = await this.alertCtrl.create({
          header: 'Selecione',
          subHeader: 'Escolha uma das opções abaixo',
          inputs: inputs,
          buttons: [
            {
              text: 'Cancelar'
            },
            {
              text: 'Confirmar',
              handler: data =>{
                this.addresssTypeChanged(data);
              }
            }
          ]
        });
    return await alert.present();
  }
  // addressStateGetOptions(){
  //   let states = cityStates.estados
  //   let stateObjects = states.map(state => {
  //     return {
  //         name: 'state' + state.sigla,
  //         type: 'radio',
  //         label: state.sigla,
  //         value: state.sigla,
  //         checked: false,
  //         color: 'warning'
  //       }
  //   })
  //   return stateObjects
  // }
  // async addressStateSelect() {
  //   let inputs = []
  //   inputs = this.addressStateGetOptions();
  //   let alert = await this.alertCtrl.create({
  //     header: 'Selecione',
  //     subHeader: 'Escolha uma das opções abaixo',
  //     inputs: inputs,
  //     buttons: [
  //       {
  //         text: 'Cancelar'
  //       },
  //       {
  //         text: 'Confirmar',
  //         handler: data =>{
  //           this.addressStateChanged(data);
  //         }
  //       }
  //     ]
  //   });
  //   return await alert.present();
  // }
  // addressCityGetOptions(){
  //   let cities = []
  //   if(this.address.state != ""){
  //     cities = cityStates.estados.find(x => x.sigla === this.address.state).cidades
  //   }
  //   let stateObjects = cities.map(city => {
  //     return {
  //         name: 'city-' + city,
  //         type: 'radio',
  //         label: city,
  //         value: city,
  //         checked: false,
  //         color: 'warning'
  //       }
  //   })
  //   return stateObjects
  // }
  // async addressCitySelect() {
  //   let inputs = []
  //   inputs = this.addressCityGetOptions();
  //   let alert = await this.alertCtrl.create({
  //     header: 'Selecione',
  //     subHeader: 'Escolha uma das opções abaixo',
  //     inputs: inputs,
  //     buttons: [
  //       {
  //         text: 'Cancelar'
  //       },
  //       {
  //         text: 'Confirmar',
  //         handler: data =>{
  //           this.addressCityChanged(data);
  //         }
  //       }
  //     ]
  //   });
  //   return await alert.present();
  // }
  addressReset(){
    this.address = new Address();
    this.address.type="MINHA CASA";
    this.mapsLoad();
    this.addressConfigManually(true);
  }
  addressZipCodeBlur(){
    const zipCode = this.addressZipCodeGet();
    const search  = true;
    let change = true;

    if (zipCode === this.address.zipCode) {
      change = false;
    }
    // isso tava dando aquele bug do endereço ***
    // if (!this.config.manually) {
    //   search = false;
    // }

    if (change) {
      this.address.zipCode = zipCode;
    }

    if (change && search) {
      if(String(this.address.zipCode).length == 9){
        this.config.loading = true;
        this.mapsAddressZipCodeApiGeocode().then((address:any)=>{
          this.address.street = address.street;
          this.address.district = address.district;
          this.address.city = address.city;
          this.address.state = address.state;
          this.mapsAddressGeocode().then((geocodeResult:any)=>{
            this.config.loading = false;
            this.address.lat = geocodeResult.lat;
            this.address.lng = geocodeResult.lng;
            this.address.country = 'BR';
            this.mapsPinMakerSetInMap();
            this.mapsUpdateCenter();
          }).catch((error:any)=>{
            this.config.loading = false;
            this.mapsLatLngGeocodeError(error);
          });
        }).catch((error)=>{
          this.config.loading = false;
          this.addressReset();
          this.errorService.print(error);
        });
      }
    }
  }
  // adressStreetBlur(){
  //   this.streetAutoComplete = []
  //   let timer = null
  //   let streets = []
  //   clearTimeout(timer); 
  //   timer = setTimeout(() => {
  //     let url:string = "https://viacep.com.br/ws/"+ this.address.state + "/" + this.address.city + "/" + this.address.street + "/json/";
  //   this.http.get(url, {}).subscribe((data: any) => {
  //     data.forEach(address => {
  //       if(!this.streetAutoComplete.find(x => x === address)) {
  //         this.streetAutoComplete.push(address.logradouro)
  //       }
  //     });
      
  //   }), (error:any)=>{
       
  //   };
  //   }, 2000)
  //   console.log(timer)
    
  // }
  addressZipCodeGet(){
    let input:any = document.getElementById('address-zipCode');
    return input.value;
  }
  addressStreetGet(){
    let input:any = document.getElementById('address-street');
    return input.value;
  }
  addressConfigManually(status:boolean){
    this.config.manually = status;
  }
  addressReadyToSave(){
    // if(
    //   this.address.lat != '' && this.address.lat != null &&
    //   this.address.lng != '' && this.address.lng != null &&
    //   this.address.street != '' && this.address.street != null &&
    //   this.address.streetNumber != '' && this.address.streetNumber != null &&
    //   this.address.district != '' && this.address.district != null &&
    //   this.address.city != '' && this.address.city != null &&
    //   this.address.state != '' && this.address.state != null &&
    //   this.address.zipCode != '' && this.address.zipCode != null && String(this.address.zipCode).length == 9
    // ){
    //   return true;
    // }else{
    //   return false;
    // }
    return true
  }
  addressLatLngGetFromWeb(){
    navigator.geolocation.getCurrentPosition((webSuccess:any)=>{
      let coords = webSuccess.coords;
      this.address.lat = coords.latitude;
      this.address.lng = coords.longitude;
      this.config.loading = true;
      this.mapsLatLngGeocode().then((geocodeResult)=>{
        this.config.loading = false;
        this.mapsGeocodeExtract(geocodeResult); 
        this.mapsPinMakerSetInMap();
        this.mapsUpdateCenter();
      }).catch((error:any)=>{
        this.mapsLatLngGeocodeError(error);
      });
    }, (webError:any)=>{
      //PERMITION NOT DENIED OR NOT SUPPORT
      this.addressConfigManually(true);
    });
  }
  addressLatLngGetFromNative(){
    this.geolocation.getCurrentPosition().then((resp) => {
      let coords = resp.coords;
      this.address.lat = coords.latitude;
      this.address.lng = coords.longitude;
      this.config.loading = true;
      this.mapsLatLngGeocode().then((geocodeResult)=>{
        this.config.loading = false;
        this.mapsGeocodeExtract(geocodeResult); 
        this.mapsPinMakerSetInMap();
        this.mapsUpdateCenter();
      }).catch((error:any)=>{
        this.mapsLatLngGeocodeError(error);
      });
     }).catch((error) => {
       //PERMITION NOT DENIED OR NOT SUPPORT
     });
  }
  async addressSave(){
    if(this.user){
      let loading = await this.loadingCtrl.create({
        message: 'Salvando endereço...'
      });
      this.address.user = this.user;
      loading.present();
      this.addressService.save(this.address).then(()=>{
        loading.dismiss();
        this.back();
      }).catch((error:any)=>{
        loading.dismiss();
        this.errorService.firebaseError(error);
      })
    }
  }
  handleAddressChange(address) {
    this.mapsGeocodeExtract(address.address_components)
  }
}

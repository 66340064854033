export class VaccineDose{
    ageMin:string = null;
    ageMax:string = null;
    ageType:string = null;
    time:string = null;
    law:string = null;
    format(){
        return{
            ageMin: this.ageMin,
            ageMax: this.ageMax,
            ageType: this.ageType,
            time: this.time,
            law: this.law
        }
    }
    importFromModel(model:VaccineDose){
        this.ageMin = model.ageMin;
        this.ageMax = model.ageMax;
        this.ageType = model.ageType;
        this.time = model.time;
        this.law = model.law;
    }
    getAgeType(){
        let result = "";
        switch(this.ageType){
            case "days":
                result = "dias";
                break;
            case "weeks":
                result = "semanas";
                break;
            case "week":
                result = "semanas";
                break;
            case "years":
                result = "anos";
                break;
            case "year":
                result = "ano";
                break;
        }
        return result;
    }
    getLabel(){
        let result = "";
        if(this.ageMin == "Reforço"){
            result = this.ageMin+" ("+this.time+")";
        }else{
            if(this.ageMax!=null){
                if(this.ageType!=null){
                    result = this.ageMin+" - "+this.ageMax+" "+this.getAgeType();
                    if(this.time!=null){
                        result = result+" ("+this.time+")";
                    }
                }else{
                    result = this.ageMin+" - "+this.ageMax;
                    if(this.time!=null){
                        result = result+" ("+this.time+")";
                    }
                }
            }else{
                if(this.ageType!=null){
                    result = this.ageMin+" "+this.getAgeType();
                    if(this.time!=null){
                        result = result+" ("+this.time+")";
                    }
                }else{
                    result = this.ageMin;
                    if(this.time!=null){
                        result = result+" ("+this.time+")";
                    }
                }
            }
        }
        return result;
    }
}
import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { PinPoints } from 'src/app/models/pinpoints';
import { UserService } from 'src/app/services/user/user.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { Components } from '@ionic/core';

@Component({
  selector: 'app-pinpoints-view',
  templateUrl: './pinpoints-view.page.html',
  styleUrls: ['./pinpoints-view.page.scss'],
})
export class PinpointsViewPage implements OnInit {
  pinpoint:any = new PinPoints();
  user:any = null;
  slidesOptions:any = {
    
  };
  isModal:any = false;
  @Input() modal: Components.IonModal;
  constructor(
    public navCtrl: NavController,
    private storage: Storage,
    private userService: UserService,
    private errorService: ErrorService
  ) {
  }

  ngOnInit() {
    this.storage.get('pinpoint:view').then((pinpoint:any)=>{
      this.pinpoint = pinpoint;
      this.userGet(this.pinpoint.user);
    });
    this.storage.get('pinpoint:modal').then((pinpoint:any)=>{
      if(pinpoint){
        this.isModal = pinpoint.status;
      }else{
        this.isModal = false;
      }
    });
  }
  //USER
  userGet(docRef:any){
    this.userService.getByDocRef(String(docRef).toString()).subscribe((user:any)=>{
      this.user = user;
    });
  }
  //PINPOINT
  pinpointTranslateType(type:any){
    let result = '';
    switch(type){
      case 'disappeared':
        result = 'Desaparecido';
        break;
      case 'lost':
        result = 'Estou Perdido';
        break;
      case 'adoption':
        result = 'Adoção';
        break;
    }
    return result;
  }
  pinpointGetColor(type:any){
    let result = '';
    switch(type){
      case 'disappeared':
        result = 'danger';
        break;
      case 'lost':
        result = 'petorange';
        break;
      case 'adoption':
        result = 'petblue';
        break;
    }
    return result;
  }
  pinpointGetImage(photo:any){
    if(photo){       
      if( ( photo.url.includes('https://') == false ) && ( String(photo.url).indexOf('images') >= 0 ) ){
        return 'assets/'+photo.url;
      }else{
        return photo.url;
      }
    }else{
      return '';
    }
  }
  back(){
    if(this.isModal){
      this.modal.dismiss();
    }else{
      this.navCtrl.back();
    }
  }
}

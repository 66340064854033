import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { Pets } from 'src/app/models/pets';

@Injectable({
  providedIn: 'root'
})
export class PetsService {
  private collection:string = 'pets';
  constructor(
    private afs: AngularFirestore    
  ) { }
  getByUser(user:any){
    return new Promise((resolve)=>{
      let valueChanges = this.afs.collection(this.collection, ref => ref.where('user', '==', String(user).toString())).valueChanges();
      valueChanges.subscribe((result:Array<Pets>)=>{
        let res = [];
        if(result.length > 0){
          result.forEach((item:Pets)=>{
            let pet = new Pets();
            pet.importFromModel(item);
            res.push(pet);
          })
        }
        resolve(res);
      });
    });
  }
  syncDocRef(docRef:any){
    return this.afs.collection(this.collection).doc(docRef).update({ docRef: docRef });
  }
  getByDoc(doc:any){
    return this.afs.collection(this.collection).doc(doc).valueChanges();
  }
  save(model:any){
    return new Promise((resolve, reject)=>{
      this.afs.collection(this.collection).add(model)
      .then((result:any)=>{
        this.syncDocRef(result.id).then(()=>{
          this.getByDoc(result.id).subscribe((pet:any)=>{
            resolve(pet);
          });
        }).catch((error:any)=>{
          reject(error);
        });
      }).catch((error:any)=>{
        reject(error);
      });
    });
  }
  deleteByDocRef(doc:any){
    return new Promise((resolve, reject)=>{
      this.afs.collection(this.collection).doc(doc).delete()
      .then(()=>{
        resolve();
      }).catch((error:any)=>{
        reject(error);
      });
    });
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
      apiKey: "AIzaSyB7_kjC3fHlmEvdI4cGmemVK1UqFbXZiB0",
      authDomain: "petspot-developer.firebaseapp.com",
      databaseURL: "https://petspot-developer.firebaseio.com",
      projectId: "petspot-developer",
      storageBucket: "petspot-developer.appspot.com",
      messagingSenderId: "209590238330",
      appId: "1:209590238330:web:86ff4758f4b1200c544fc3",
      measurementId: "G-SZ60V20CVC"
  },
  prod: {
    apiKey: "AIzaSyCQJy0Dw7jV9ThWgxe_sf1wR-PDWqVEahA",
    authDomain: "petspot-producao.firebaseapp.com",
    databaseURL: "https://petspot-producao.firebaseio.com",
    projectId: "petspot-producao",
    storageBucket: "petspot-producao.appspot.com",
    messagingSenderId: "438092568897",
    appId: "1:438092568897:web:a8b469c46804d6bc"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Vaccine } from './vaccine';
import { VaccineDose } from './vaccine-dose';
import { VaccinationCardDoseDates } from './vaccination-card-dose-dates';
import { VaccinationCardDoseReminders } from './vaccination-card-dose-reminders';

export class VaccinationCardDose{
    vaccine:Vaccine = new Vaccine();
    dose:VaccineDose = new VaccineDose();   
    dates:VaccinationCardDoseDates = new VaccinationCardDoseDates();
    reminders:VaccinationCardDoseReminders = new VaccinationCardDoseReminders();
    constructor(){}
    importFromModel(model:VaccinationCardDose){
        this.vaccine.importFromModel(model.vaccine);
        this.dose.importFromModel(model.dose);
        this.dates.importFromModel(model.dates);
        this.reminders.importFromModel(model.reminders);
    }
    format(){
        return {
            vaccine: this.vaccine.format(),
            dose: this.dose.format(),
            dates: this.dates.format(),
            reminders: this.reminders.format()
        }
    }
}
import * as moment from 'moment';

export class PinPoints{
    type:string = null;
    data:object = null;
    docRef:string = null;
    date:string = null;
    user:string = null;
    done:boolean = false;
    format(){
        return {
            type: this.type,
            data: this.data,
            done: this.done,
            date: this.date,
            docRef: this.docRef,
            user: this.user
        };
    }
}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }
  public dataIsEmpty(value:any){
    if(value == null || value == ""){
        return true;
    }else{
        return false;
    }
  }
  public checkEmailFormat(email:string){
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
  }
  public checkPhoneNumberFormat(phoneNumber:any){
      let result              = true,
          phoneNumber_str     = phoneNumber;
      phoneNumber_str = phoneNumber_str.replace("(", "");
      phoneNumber_str = phoneNumber_str.replace(")", "");
      phoneNumber_str = phoneNumber_str.replace("-", "");
      phoneNumber_str = phoneNumber_str.replace(" ", "").trim();
      if(!isNaN(phoneNumber_str)){
          if(
              phoneNumber_str.length < 10 || 
              phoneNumber_str.length > 11
          ){
              result = false;
          }
      }else{
          result = false;
      }
      return result;
  }
  public checkBirthDate(birthDate:any){
      let result          = true,
          birthDate_arr   = birthDate.split('/'),
          currentDate     = new Date(),
          year_chk        = true,
          month_chk       = true,
          day_chk         = true;
      if(parseInt(birthDate_arr[2]) > currentDate.getFullYear()){
          year_chk = false;
      }
      if(year_chk){
          if(parseInt(birthDate_arr[2]) == currentDate.getFullYear()){
              if(parseInt(birthDate_arr[1]) > currentDate.getMonth() || parseInt(birthDate_arr[1]) > 12){
                  month_chk = false;
              }
              if(month_chk){
                  if(parseInt(birthDate_arr[0]) > currentDate.getDate() || parseInt(birthDate_arr[0]) > 31){
                      day_chk = false;
                  }      
                  if(!day_chk){
                      result = false;
                  }              
              }else{
                  result = false;
              }
          }
      }else{
          result = false;     
      }
      return result;
  }
  public checkBirthDateFormat(birthDate:any){
      let result              = true;
      if(birthDate.length == 10){
          let birthDate_arr       = birthDate.split('/'),
              birthDate_str       = birthDate_arr[0]+birthDate_arr[1]+birthDate_arr[2]+"";
          if(!isNaN(parseInt(birthDate_str))){
              if(birthDate_arr[2].length != 4 || isNaN(birthDate_arr[2])){
                  result = false;
              }            
              if(birthDate_arr[1].length != 2 || isNaN(birthDate_arr[1])){
                  result = false;
              }            
              if(birthDate_arr[0].length != 2 || isNaN(birthDate_arr[0])){
                  result = false;
              }            
          }else{
              result = false;
          }
      }else{
          result = false;
      }
      return result;
  }
  public checkBirthDateOfAge(birthDate:any){
      let result          = true,
          birthDate_arr   = birthDate.split('/'),
          currentDate     = new Date(),
          year_calc       = currentDate.getFullYear() - parseInt(birthDate_arr[2]);
      if(year_calc >= 18){
          result = true;
      }else{
          result = false;
      }
      return result;
  }
}

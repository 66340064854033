import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NavController, AlertController, NavParams, LoadingController } from '@ionic/angular';
import { ErrorService } from 'src/app/services/error/error.service';
import { Storage } from '@ionic/storage';
import { Pets } from 'src/app/models/pets';
import { Vaccination } from 'src/app/models/vaccination';
import { Components } from '@ionic/core';
import * as moment from 'moment';
import { VaccinationCard } from 'src/app/models/vaccination-card';
import { VaccinesService } from 'src/app/services/vaccines/vaccines.service';
import { Vaccine } from 'src/app/models/vaccine';
import { VaccineDose } from 'src/app/models/vaccine-dose';
import { VaccinationCardDose } from 'src/app/models/vaccination-card-dose';
import { VaccinationCardsService } from 'src/app/services/vaccination-cards/vaccination-cards.service';

@Component({
  selector: 'app-vaccination-card-pet-add',
  templateUrl: './vaccination-card-pet-add.page.html',
  styleUrls: ['./vaccination-card-pet-add.page.scss'],
})
export class VaccinationCardPetAddPage implements OnInit {
  pet: Pets = new Pets();
  user: any = null;
  vaccinationCardDose: VaccinationCardDose = new VaccinationCardDose();
  vaccines: Array<Vaccine> = [];
  vaccineDose: VaccineDose = new VaccineDose();
  vaccinationCard: VaccinationCard = new VaccinationCard();
  today: any = moment().format('YYYY-MM-DD');
  maxDate: any = ''
  @ViewChild('datepicker') datepicker: any;
  @ViewChild('datepicker2') datepicker2: any;
  @ViewChild('reminderFirst') reminderFirst: any;
  @ViewChild('reminderSecond') reminderSecond: any;
  views: any = {
    type: true,
    name: false,
    dose: false,
    date: {
      last: false,
      next: false,
    },
    reminders: {
      first: false,
      second: false,
    },
  };
  @Input() modal: Components.IonModal;
  constructor(
    public navCtrl: NavController,
    private errorService: ErrorService,
    private storage: Storage,
    private alertCtrl: AlertController,
    private navParams: NavParams,
    private vaccinesService: VaccinesService,
    private vaccinationCardService: VaccinationCardsService,
    private loadingCtrl: LoadingController
  ) {}

  ngOnInit() {
    const now = new Date()
    console.log(now)
    now.setFullYear(now.getFullYear() + 3)
    console.log(now)
    this.maxDate = moment(now).format('YYYY-MM-DD')
  }
  ionViewWillEnter() {
    const params = this.navParams.get('vaccinationCard');
    if (params != null) {
      this.vaccinationCard.importFromModel(params);
    }
    this.vaccinesLoadFromPetInfo();
  }
  back() {
    this.modal.dismiss();
  }
  // VACCINES
  vaccinesLoadFromPetInfo() {
    this.vaccinesService
      .loadFromPetInfo(this.vaccinationCard.getPetInfo())
      .then((vaccines: Array<Vaccine>) => {
        console.log(vaccines)
        vaccines.forEach((item: Vaccine) => {
          const vaccine = new Vaccine();
          vaccine.importFromModel(item);
          this.vaccines.push(vaccine);
        });
      });
  }
  // VACCINATION
  async vaccinationTypeSelect() {
    const alert = await this.alertCtrl.create({
      header: 'Tipos de Vacina',
      subHeader: 'Selecione um dos tipos de vacinas abaixo:',
      inputs: this.vaccinationGetTypesList(),
      buttons: [
        {
          text: 'Cancelar',
        },
        {
          text: 'Confirmar',
          handler: async (data) => {
            this.vaccinationCardDose.vaccine = data;
          },
        },
      ],
    });
    await alert.present();
  }
  async vaccinationDoseSelect() {
    const alert = await this.alertCtrl.create({
      header: 'Qual Dose?',
      subHeader:
        'Informe qual dose o ' +
        this.vaccinationCard.pet.name +
        ' tomou:',
      inputs: this.vaccinationGetDoseList(),
      buttons: [
        {
          text: 'Cancelar',
        },
        {
          text: 'Confirmar',
          handler: async (data) => {
            console.log(this.vaccinationCard);
            this.vaccinationCardDose.dose = data;
          },
        },
      ],
    });
    await alert.present();
  }
  vaccinationDateSelect(type: any) {
    if (type === 'last') {
      this.datepicker.open();
    } else {
      this.datepicker2.open();
    }
  }
  vaccinationReminderSelect(type: any) {
    if (type === 'first') {
      this.reminderFirst.open();
    } else {
      this.reminderSecond.open();
    }
  }
  vaccinationGetTypesList() {
    const result = [];
    this.vaccines.forEach((item: Vaccine) => {
      const option: any = {
        name: item.name,
        type: 'radio',
        label: item.name,
        checked: false,
        value: item,
      };
      if (this.vaccinationCardDose.vaccine.name === item.name) {
        option.checked = true;
      }
      result.push(option);
    });
    return result;
  }
  vaccinationGetDoseList() {
    const result = [];
    this.vaccinationCardDose.vaccine.doses.forEach(
      (item: VaccineDose, index: number) => {
        const optionLabel = item.getLabel();
        const option: any = {
          name: 'dose-' + index,
          type: 'radio',
          label: optionLabel,
          checked: false,
          value: item,
        };
        if (this.vaccinationCardDose.dose.getLabel() === optionLabel) {
          option.checked = true;
        }
        result.push(option);
      }
    );
    return result;
  }
  vaccinationViewGetActive() {
    let result = 'type';
    if (this.views.type) {
      result = 'type';
    }
    if (this.views.name) {
      result = 'name';
    }
    if (this.views.dose) {
      result = 'dose';
    }
    if (this.views.date.last) {
      result = 'date-last';
    }
    if (this.views.date.next) {
      result = 'date-next';
    }
    if (this.views.reminders.first) {
      result = 'reminders-first';
    }
    if (this.views.reminders.second) {
      result = 'reminders-second';
    }
    return result;
  }
  vaccinationViewNext() {
    const viewActived = this.vaccinationViewGetActive();
    switch (viewActived) {
      case 'type':
        if (
          this.vaccinationCardDose.vaccine.name != null &&
          this.vaccinationCardDose.vaccine.name !== ''
        ) {
          this.views.type = false;
          this.views.dose = true;
        } else {
          this.errorService.print('Selecione o tipo de vacina para continuar');
        }
        break;
      case 'dose':
        if (
          this.vaccinationCardDose.dose.getLabel() != null &&
          this.vaccinationCardDose.dose.getLabel() !== ''
        ) {
          this.views.dose = false;
          this.views.date.last = true;
        } else {
          this.errorService.print(
            'Informe quantas doses o ' +
              this.vaccinationCard.pet.name +
              ' já tomou para continuar'
          );
        }
        break;
      case 'date-last':
        if (
          this.vaccinationCardDose.dates.last != null &&
          this.vaccinationCardDose.dates.last !== ''
        ) {
          this.views.date.last = false;
          this.views.date.next = true;
        } else {
          this.errorService.print(
            'Informe a data da última dose que o ' +
              this.vaccinationCard.pet.name +
              ' tomou para continuar'
          );
        }
        break;
      case 'date-next':
        if (
          this.vaccinationCardDose.dates.next != null &&
          this.vaccinationCardDose.dates.next !== ''
        ) {
          this.views.date.next = false;
          this.views.reminders.first = true;
        } else {
          this.errorService.print(
            'Informe a data da próxima vacina para continuar'
          );
        }
        break;
      case 'reminders-first':
        if (
          this.vaccinationCardDose.reminders.first != null &&
          this.vaccinationCardDose.reminders.first !== ''
        ) {
          this.views.reminders.first = false;
          this.views.reminders.second = true;
        } else {
          this.errorService.print(
            'Informe quando quer que lembramos você para continuar'
          );
        }
        break;
      case 'reminders-second':
        this.vaccinationSave();
        break;
    }
  }
  vaccinationViewPrevious() {
    const viewActived = this.vaccinationViewGetActive();
    switch (viewActived) {
      case 'type':
        this.views.type = true;
        this.views.dose = false;
        break;
      case 'dose':
        this.views.dose = false;
        this.views.type = true;
        break;
      case 'date-last':
        this.views.date.last = false;
        this.views.dose = true;
        break;
      case 'date-next':
        this.views.date.next = false;
        this.views.date.last = true;
        break;
      case 'reminders-first':
        this.views.reminders.first = false;
        this.views.date.next = true;
        break;
      case 'reminders-second':
        this.views.reminders.second = false;
        this.views.reminders.first = true;
        break;
    }
  }
  async vaccinationSave() {
    const loading = await this.loadingCtrl.create({
      message:
        'Salvando na carteirinha de vacinação do ' +
        this.vaccinationCard.pet.name +
        ', aguarde...',
    });
    loading.present();
    this.vaccinationCardService
      .addInDoses(
        this.vaccinationCard.docRef,
        this.vaccinationCard.doses,
        this.vaccinationCardDose
      )
      .then(() => {
        loading.dismiss().then(()=> {
          location.reload()
        })
      })
      .catch((error: any) => {
        console.log(error)
        loading.dismiss();
        this.errorService.appError(error);
      });
  }
  // PET
  petGetFromStorage() {
    return new Promise((resolve, reject) => {
      this.storage
        .get('vaccination:pet')
        .then((result: any) => {
          if (result != null) {
            resolve(result);
          } else {
            reject(null);
          }
        })
        .catch((error: any) => {
          reject(null);
        });
    });
  }
  // DATE
  dateUpdate(type: any, event: any) {
    const date = moment(event.target.value);
    if (type == 'last') {
      this.vaccinationCardDose.dates.last = date.format('DD/MM/YYYY');
    } else {
      this.vaccinationCardDose.dates.next = date.format('DD/MM/YYYY');
    }
  }
  dateUpdate2(type: any, event: any) {
    const date = moment(event.target.value);
    if (type == 'first') {
      this.vaccinationCardDose.reminders.first = date.format('DD/MM/YYYY');
    } else {
      this.vaccinationCardDose.reminders.second = date.format('DD/MM/YYYY');
    }
  }
}

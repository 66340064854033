export class VaccinationCardDoseReminders{
    first:string = null;
    second:string =null;
    importFromModel(model:VaccinationCardDoseReminders){
        this.first = model.first;
        this.second = model.second;
    }
    format(){
        return {
            first: this.first,
            second: this.second
        }
    }
}
export class Pets{
    type:string = 'Gato';
    size:any = {
      text: 'P',
      value: 1
    };
    name:string = null;
    breed:string = null;
    rga:string = null;
    photos:any = [];
    sex:string = 'Fêmea';
    age:string = '0';
    address:any = null;
    eyesColors:any = [];
    fur:string = 'Curto';
    colors:any = [];
    user:string = null;
    description:string = null;
    docRef:string = null;
    getColorsList(){
        return [
            'Amarelo',
            'Beje',
            'Branco',
            'Cinza',
            'Malhado',
            'Marrom',
            'Preto',
            'Tigrado'
        ];
    }
    getEyesColorsList(){
        return [
          'Amarelos',
          'Azuis',
          'Castanhos',
          'Pretos',
          'Verdes',
          'Heterocromia'
        ];
    }
    format(){
        return {
            type: this.type,
            size: this.size,
            name: this.name,
            breed: this.breed,
            rga: this.rga,
            photos: this.photos,
            sex: this.sex,
            age: this.age,
            address: this.address,
            eyesColors: this.eyesColors,
            fur: this.fur,
            colors: this.colors,
            user: this.user,
            description: this.description,
            docRef: this.docRef
        };
    }
    importFromModel(model:Pets){
        this.type = model.type;
        this.size = model.size;
        this.name = model.name;
        this.breed = model.breed;
        this.rga = model.rga;
        this.photos = model.photos;
        this.sex = model.sex;
        this.age = model.age;
        this.address = model.address;
        this.eyesColors = model.eyesColors;
        this.fur = model.fur;
        this.colors = model.colors;
        this.eyesColors = model.eyesColors;
        this.user = model.user;
        this.description = model.description;
        this.docRef = model.docRef;
    }
    getAgeLife(){
        let size = this.size.value,
            age = parseInt(this.age),
            result = null;
        switch(size){
            // Confirmar a regra para adulto e filhote posteriormente
            case 1:
                //PEQUENO
                var ageWeeks = age*12;
                if(ageWeeks >= 12){
                    result = "Adulto";
                }else{
                    result = "Filhote";
                }
                break;
            case 2:
                //MÉDIO
                var ageWeeks = age*12;
                if(ageWeeks >= 12){
                    result = "Adulto";
                }else{
                    result = "Filhote";
                }
                break;
            case 3:
                //GRANDE
                var ageWeeks = age*12;
                if(ageWeeks >= 12){
                    result = "Adulto";
                }else{
                    result = "Filhote";
                }
                break;
        }
        return result;
    }
    getTypeEnglish(){
        let type = this.type,
            result = null;
        switch(type){
            case "Miau":
                result = "cat";
                break;
            default:
                result = "dog";
                break;
        }
        return result;
    }
}
import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private collection:string = 'address';
  constructor(
    private afs: AngularFirestore    
  ) { }
  private extractModel(address:any){
    return {
      type: address.type,
      text: address.text,
      lat: address.lat,
      lng: address.lng,
      street: address.street,
      streetNumber: address.streetNumber,
      district: address.district,
      complement: address.complement,
      zipCode: address.zipCode,
      city: address.city,
      state: address.state,
      country: address.country,
      user: address.user,
    };
  }
  getByUser(user:any){
    return new Promise((resolve)=>{
      let valueChanges = this.afs.collection(this.collection, ref => ref.where('user', '==', String(user).toString())).valueChanges();
      valueChanges.subscribe((result:any)=>{
        resolve(result);
      });
    });
  }
  syncDocRef(docRef:any){
    return this.afs.collection(this.collection).doc(docRef).update({
      docRef: docRef
    });
  }
  save(address:any){
    return new Promise((resolve, reject)=>{
      let model = this.extractModel(address);
      this.afs.collection(this.collection).add(model)
      .then((result:any)=>{
        this.syncDocRef(result.id).then(()=>{
          resolve({});
        }).catch((error:any)=>{
          reject(error);
        });
      }).catch((error:any)=>{
        reject(error);
      });
    });
  }
  deleteByDocRef(doc:any){
    return new Promise((resolve, reject)=>{
      this.afs.collection(this.collection).doc(doc).delete()
      .then(()=>{
        resolve();
      }).catch((error:any)=>{
        reject(error);
      });
    });
  }
}

import { Pets } from './pets';
import { VaccinationCardDose } from './vaccination-card-dose';

export class VaccinationCard{
    pet:Pets = new Pets();
    hasHistoric:boolean = null;
    doses:Array<VaccinationCardDose> = [];
    docRef:string = null;
    constructor(){}
    importFromModel(model:VaccinationCard){
        this.pet.importFromModel(model.pet);
        this.hasHistoric = model.hasHistoric;
        this.docRef = model.docRef;
        if(model.doses.length>0){
            model.doses.forEach((item:VaccinationCardDose)=>{
                let dose = new VaccinationCardDose();
                dose.importFromModel(item);
                this.doses.push(dose);
            });
        }
    }
    format(){
        return {
            pet: this.pet.format(),
            hasHistoric: this.hasHistoric,
            doses: this.doses,
            docRef: this.docRef
        }
    }
    getPetInfo(){
        return {
            petAge: this.pet.getAgeLife(),
            petType: this.pet.getTypeEnglish()
        }
    }
}
import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-pinpoint-add-success',
  templateUrl: './pinpoint-add-success.page.html',
  styleUrls: ['./pinpoint-add-success.page.scss'],
})
export class PinpointAddSuccessPage implements OnInit {
  @Input() modal: Components.IonModal;
  constructor(
    private platform: Platform,
  ) { }

  ngOnInit() {
  }
  back(){
    this.modal.dismiss();
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { VaccinationCard } from 'src/app/models/vaccination-card';
import { VaccinationCardDose } from 'src/app/models/vaccination-card-dose';

@Injectable({
  providedIn: 'root'
})
export class VaccinationCardsService {
  private collection:string = 'vaccinationCards';
  constructor(
    private afs: AngularFirestore   
  ) { }
  getByPet(petDocRef:string){
    return new Promise((resolve, reject)=>{
      let valueChanges = this.afs.collection(this.collection, ref => ref.where('pet.docRef', '==', petDocRef)).valueChanges();
      valueChanges.subscribe((result:any)=>{
        if(result.length > 0){
          resolve(result[0]);
        }else{
          reject();
        }
      });
    });
  }
  syncDocRef(docRef:any){
    return this.afs.collection(this.collection).doc(docRef).update({ docRef: docRef });
  }
  getByDoc(doc:any){
    return this.afs.collection(this.collection).doc(doc).valueChanges();
  }
  load(){
    return this.afs.collection(this.collection).valueChanges();
  }
  save(model:any){
    return new Promise((resolve, reject)=>{
      this.afs.collection(this.collection).add(model)
      .then((result:any)=>{
        this.syncDocRef(result.id).then(()=>{
          this.getByDoc(result.id).subscribe((pet:any)=>{
            resolve(pet);
          });
        }).catch((error:any)=>{
          reject(error);
        });
      }).catch((error:any)=>{
        reject(error);
      });
    });
  }
  deleteByDocRef(doc:any){
    return new Promise((resolve, reject)=>{
      this.afs.collection(this.collection).doc(doc).delete()
      .then(()=>{
        resolve();
      }).catch((error:any)=>{
        reject(error);
      });
    });
  }
  done(docRef:any){
    return new Promise((resolve, reject)=>{
      let params = {
        done: true
      };
      this.afs.collection(this.collection).doc(docRef).update(params)
      .then(()=>{
        resolve();
      }).catch((error:any)=>{
        reject(error);
      });
    });
  }
  addInDoses(docRef:string, vaccinationCardDoses:Array<VaccinationCardDose>, dose:VaccinationCardDose){
    return new Promise((resolve, reject)=>{
      let doses = [];
      if(vaccinationCardDoses.length > 0){
        vaccinationCardDoses.forEach((item:VaccinationCardDose)=>{
          doses.push(item.format());
        });
      }
      doses.push(dose.format());
      let params = {
        doses: doses
      };
      console.log(doses)
      this.afs.collection(this.collection).doc(docRef).update(params)
      .then(()=>{
        resolve();
      }).catch((error:any)=>{
        reject(error);
      });
    });
  }
  updateDoses(docRef:string, vaccinationCardDoses:Array<VaccinationCardDose>){
    return new Promise((resolve, reject)=>{
      let doses = [];
      if(vaccinationCardDoses.length > 0){
        vaccinationCardDoses.forEach((item:VaccinationCardDose)=>{
          doses.push(item.format());
        });
      }
      let params = {
        doses: doses
      };
      this.afs.collection(this.collection).doc(docRef).update(params)
      .then(()=>{
        resolve();
      }).catch((error:any)=>{
        reject(error);
      });
    });
  }
}

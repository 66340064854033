import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { PinpointsViewPage } from './pinpoints-view.page';
import { UserService } from 'src/app/services/user/user.service';
import { ErrorService } from 'src/app/services/error/error.service';

const routes: Routes = [
  {
    path: '',
    component: PinpointsViewPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes)
  ],
  declarations: [PinpointsViewPage],
  providers: [
    UserService,
    ErrorService
  ]
})
export class PinpointsViewPageModule {}
